import React, { Component } from 'react';

import InputMask from 'react-input-mask';
import { isRegularExpressionLiteral } from 'typescript';


/**
 * @typedef {object} props
 * @prop {List} months
 * @prop {Function} handlePickDay
 * @prop {String} title
 * @prop {String} subtitle
 * @prop {String} image
 * @prop {object} state
 * @extends {Component<Props>}
 */
export default class CIDetailPage extends Component {

    constructor(props) {
        super(props)
    }

    state = {
        error: false,
        inputs: this.props.inputs
    }
    
    validateAndNext = () => {
        let canGoToNext = true
    
        for(let input of this.state.inputs) {
            let validation = input.validation || (() => false)
            let error = validation(this.props.state[input.name])
            
            if (error) {
                canGoToNext = false
                input['error'] = error
            }
        }

        if(canGoToNext){
            this.props.changePage()
        }
        else
            this.setState({})
    }

    componentWillReceiveProps(nextProps) {
        if (this.props !== nextProps) {
            this.setState(nextProps);
        }
    }

    previous = () => {
        this.props.changePage(true)
    }

    // Por algum motivo o input renderizava sem valor e mantinha algum cache padrão da escolha do campo anterior
    formatOptions = () => {
        let dropdown = document.getElementById("dropdown")
        if (!dropdown) return   
        dropdown.value = ""
    }

    render() {
        return (
                <div className="whiteCard container">  
                
                    
                    <div className="row col-12  pcard">
                   
                        <div className="col-12 col-lg-6  mobile">
                        <img className= "imgCi" src={this.props.image}/>
                        </div>

                        <h4 className="col-12">{this.props.title}</h4>

                        {this.props.subtitle ? <h6 className="col-12">{this.props.subtitle}</h6> : <h6 className="col-12">-</h6>}
                        
                        <div className="col-12 col-lg-6 confcard borderline">
                        <img className= "imgCi" src={this.props.image}/>
                        </div>

                        <div className="col-12 col-lg-6 inputcard confcard">
                            {this.state.inputs.map( i =>{
                                let render = true
                                if (i.condition) render = i.condition()
                            
                                this.formatOptions()
                                return render ?     
                                <>
                                    
                                    {/* Mensagem de erro */}
                                    <div className = "col-12 col-lg-9" >
                                        <h6 class="col-12 col-lg-12 d-flex justify-content-center" style = {{textAlign: "center !important"}}> {i.label} </h6>
                                        {i.error ? <div style = {{color: 'red', fontSize: 12, position: 'absolute', right: '10%', top: '10%' }}>{i.error}</div> : <></>}
                                    </div>

                                    
                                    {// Drop down caso tenha options no objeto do input
                                        i.options ? 
                                    <select
                                        id = "dropdown"
                                        class = "input col-12 col-lg-8 inConf"
                                        name = {i.name}
                                        value = {this.props.state[i.name]}
                                        onChange = {
                                            i.readonly ? null :(evt) => {
                                                this.props.changeText(evt.target.value, i.name)
                                            }}>
                                        <option value="" selected disabled></option>
                                        {i.options.map(opt =>
                                            <option value = {opt}>{opt}</option>    
                                        )}
                                    </select>
                                    
                                    :
                                    i.textarea ? 
                                    
                                    // Caso seja um inputão
                                    <textarea
                                        className = "input col-12 col-lg-8 inConf"
                                        
                                        value = {this.props.state[i.name] || ""}
                                        onChange = { i.readonly ? null :(evt) => this.props.changeText(evt.target.value, i.name)}
                                    /> :

                                    // Input com máscara caso não o tenha
                                    <InputMask
                                        className = "input col-12 col-lg-8 inConf"
                                        mask = {i.mask}
                                        value = {this.props.state[i.name] || ""}
                                        onChange = { i.readonly ? null :(evt) => this.props.changeText(evt.target.value, i.name)}
                                    />} 
                                </>
                                : <></>
                                
                                }
                            )}
                        
                        </div>

                        {this.props.first || <button onClick = {this.previous} className = "mobnone btnC">Voltar</button>}

                        <button onClick = {this.validateAndNext} className = "btnC">
                            {this.props.last ? "Salvar" : "Próximo"}
                        </button>
                        
                        {this.props.first || <button onClick = {this.previous} className = "btnC desknone">Voltar</button>}

                    </div>
                        
            </div>
           
        )
    }
}
