import React, { Component } from 'react';
import CI2 from '../assets/CI2.png'
import SWW from '../assets/sww.png'

export default class AppointmentGoneWrong extends Component {
    

    goToInitial = () => {

        let token = this.props.token
        //TODO: ver como navegar entre as rotas do react-router
        window.location.replace('http://localhost:3000/ci-initial/token/'+token);
    }
    
    render() {
        return (
            <div className="col-12 justify-content-center d-flex thanksPage row ">
                <div className="col-12 d-flex justify-content-center ">
                    <img className= "imgthankslg" src={SWW}/>
                </div>

                <div className=" col-12 d-flex justify-content-center row mt-5">
                    <h4 className="col-12"> Ops, algo deu errado!</h4>
                    <h4 className="col-12"> Estamos processando seu pagamento mas você terá que escolher um novo horário.</h4>
                </div>

                <button className = 'btnC' onClick = {this.goToInitial}>Agendar</button>
                           
          </div>
        );
    }
}
