import ThanksPage from '../thanks'
import CIDetailPage from './../ci_detail_page'

import Social from './../../assets/social.png'
import Conf from './../../assets/CI1.png'
import Cont from './../../assets/CI2.png'
import Dets from './../../assets/CI3.png'
import MED from './../../assets/med.png'




function getCommonRecordPage(state, changeText, changePage, index) {
    let pages = [
        () => <CIDetailPage
            title = "Vamos confirmar seus dados pessoais?"
            subtitle = "Lembre-se, os dados devem ser do paciente!"
            image = {Conf}
            state = {state}
            first
            inputs = {[
                {label: "Nome Completo", name: "fullName" ,validation: (value) => {
                    if (value === 'batata') return "Você não pode chamar batata"
                }},
                {label: "E-mail", name: "ciEmail", readonly: true},
                {label: "WhatsApp", name: "phone", mask: "(99) 99999-9999"},
                {label: "CPF", name: "cpf", mask: "999.999.999-99"}
            ]}
            changeText = {changeText}
            changePage = {changePage}
        />,

        () => <CIDetailPage
            title = "Este é o seu endereço atual?"
            image = {Conf}
            state = {state}
            inputs = {[
                {label: "CEP", name: "zip", mask: "99999-999"},
                {label: "Endereço", name: "street"},
                {label: "Cidade", name: "city"},
                {label: "Estado", name: "state"}

            ]}
            changeText = {changeText}
            changePage = {changePage}
        />,

        () => <CIDetailPage
            title = "Conte-nos um pouco mais sobre você..."
            subtitle = "Lembre-se, os dados devem ser do paciente!"
            image = {Dets}
            state = {state}
            inputs = {[
                {label: "Cidade e estado onde nasceu", name: "birthCity",},
                {label: "Profissão/Ocupação", name: "occupation",},
                {label: "Data de Nascimento", name: "birthday", mask: "99/99/9999"},
                {label: "Estado Civil", name: "civilStatus",}
            ]}
            changeText = {changeText}
            changePage = {changePage}
        />,

        () => <CIDetailPage
            title = "Precisamos de um contato de confiança..."
            subtitle = "Fique tranquilo, entraremos em contato apenas em situações de risco à sua vida."
            state = {state}
            image = {Cont}
            inputs = {[
                {label: "Nome", name: "contactName",},
                {label: "Qual sua relação (pai, mãe, amigo, colega)", name: "contactRelation",},
                {label: "Telefone para contato", name: "contactPhone", mask: "99 99999-9999"},
            ]}
            changeText = {changeText}
            changePage = {changePage}
        />,

        () => <CIDetailPage
            title = "Atendimento psiquiátrico"
            state = {state}
            image = {Dets}
            inputs = {[
                {label: "Atualmente, você faz acompanhamento psiquiátrico", name: "psycoStatus", options: ['Sim', 'Não']},
                {label: "Qual nome do profissional?", name: "psycoName", condition: () => state.psycoStatus === "Sim"},
                {label: "Telefone do profissional", name: "psycoPhone", condition: () => state.psycoStatus === "Sim", mask: "99 99999-9999"},
                {label: "Algum comentário sobre seu psiquiatra?", name: "coments", condition: () => state.psycoStatus === "Sim"},
            ]}
            changeText = {changeText}
            changePage = {changePage}
        />,

        () => <CIDetailPage
            title = "Como você conheceu a Eurekka?"
            subtitle = "Nos conte sobre sua experiência"
            state = {state}
            image = {Social}
            inputs = {[
                {label: "Como conheceu a terapia da eurekka?", name: "sourceEurekka", options: [
                    'Google', 'Facebook', 'Instagram', 'Indicação', 'Outros'
                ]},
                {label: "Nos conte um pouco sobre", name: "otherAbout", condition: () => state.sourceEurekka === "Outros"},
                {label: "Qual nome de quem te indicou", name: "sourceIndication", condition: () => state.sourceEurekka === "Indicação"},
            ]}
            changeText = {changeText}
            changePage = {changePage}
        />,
    ]
    return pages
}




function getGenericRecordPage(state, changeText, changePage, index) {
    let commonPages = getCommonRecordPage(state, changeText, changePage)
    let genericPages =  [
        () => <CIDetailPage
            title = "Ficha do paciente"
            image = {MED}
            state = {state}
            changePage = {changePage}
            changeText = {changeText}
            inputs = {[
                {label: "Por que você está procurando atendimento medico", name: "reason", textarea: true},
                {label: "Está com os sintomas há quanto tempo?", name: "symptoms"},
                
            ]}
        />,

        () => <CIDetailPage
        title = "Ficha do paciente"
        image = {MED}
        state = {state}
        changePage = {changePage}
        changeText = {changeText}
        inputs = {[
           
            {label: "Mediu sua temperatura corporal hoje?", name: "temperature", options: ['Sim', 'Não']},
            {label: "Está com febre?", name: "fever", options: ['Sim', 'Não']},
        ]}
    />,

        () => <CIDetailPage
        
            state = {state}
            title = "Ficha do paciente"
            image = {MED}
            changePage = {changePage}
            changeText = {changeText}
            inputs = {[
                {label: "Você faz tratamento para alguma doença?", name: "treatment", options: ['Sim', 'Não']},
                {label: "Qual o diagnóstico?", textarea: true, name: "diagnostic", condition: () => state.treatment == "Sim"}, 
                {
                    label: "Quais as medicações e dosagens que você utiliza? Alguma mudança recente?",
                    name: "medications"
                },
            ]}
        />,

        () => <CIDetailPage
        
            state = {state}
            title = "Ficha do paciente"
            image = {MED}
            changePage = {changePage}
            changeText = {changeText}
            
            inputs = {[
                {label: "Qual a sua altura?", name: "height"},
                {label: "Qual o seu peso?", name: "weight"},
                {label: "Você fuma?", name: "smoke", options: ['Sim', 'Não']},
                {label: "Você bebe?", name: "alcohol", options: ['Sim', 'Não']},
                
            ]}
        />,
        
        () => <CIDetailPage
        
        state = {state}
        title = "Ficha do paciente"
        image = {MED}
        changePage = {changePage}
        changeText = {changeText}
        last
        inputs = {[
           
            {label: "Você usa drogas?", name: "drugs", options: ['Sim', 'Não']},
            {label: "Quais?", name: "whichDrugs", condition: () => state.drugs == 'Sim'},
            {label: "Qual frequência?", name: "drugFrequecy", condition: () => state.drugs == 'Sim'},
        ]}
    />,

        () => <ThanksPage/>
    ]

    let allPages = commonPages.concat(genericPages)
    return {page: allPages[index], length: allPages.length}
}

function getPsyRecordPage(state, changeText, changePage, index) {
    let commonPages = getCommonRecordPage(state, changeText, changePage, index)
    let psyPages =  [
        () => <CIDetailPage
            state = {state}
            title = "Ficha do paciente"
            image = {MED}
            changePage = {changePage}
            changeText = {changeText}
            inputs = {[
                {label: "Por que você está procurando atendimento medico", name: "reason", textarea: true},
                {label: "Você já consulta com psiquiatra?", name: "hasPsychiatrist", options: ['Sim', 'Não']},
                {label: "Quando foi a ultima consulta?", name: "lastConsultDate", mask: "99/99/9999", condition: () => state.hasPsychiatrist == 'Sim'}
            ]}/>,

        () => <CIDetailPage
            state = {state}
            title = "Ficha do paciente"
            image = {MED}
            changePage = {changePage}
            changeText = {changeText}       
            inputs = {[
                {label: "Você já esteve em uma ala psiquiátrica?", name: "wing", options: ['Sim', 'Não']},
                {label: "Quando foi a última vez?", name: "wingLastTime", mask: "99/99/9999", condition: () => state.wing == "Sim"},
                {label: "Quantas vezes você já esteve lá?", name: "wingCount", condition: () => state.wing == "Sim"}

            ]}
        />,

        () => <CIDetailPage
            state = {state}
            title = "Ficha do paciente"
            image = {MED}
            changePage = {changePage}
            changeText = {changeText}       
            inputs = {[
                {label: "Você já recebeu um diagnóstico psiquiátrico ou nerológico?", name: "diagnostic", options: ['Sim', 'Não']},
                {label: "Qual?", name: "diagnosticText", textarea: true,condition: () => state.diagnostic == 'Sim'},
                {label: "Quando?", name: "diagnosticDate", mask: "99/99/9999", condition: () => state.diagnostic == 'Sim'}
            ]}
        />,

        () => <CIDetailPage
            state = {state}
            title = "Ficha do paciente"
            image = {MED}
            changePage = {changePage}
            changeText = {changeText}       
            inputs = {[
                {label: "Você utiliza medicações psiquiátricas/neurológicas?", name: "hasPsyMedications", options: ['Sim', 'Não']},
                {label: "Quais (coloque também as dosagens)?",textarea: true, name: "psyMedications", condition: () => state.hasPsyMedications == 'Sim'},
                {label: "Alguma mudança recente?", name: "hasPsyMedicationsChange", options: ['Sim', 'Não']},
                {label: "Quais?", textarea: true, name: "psyMedicationsChange", condition: () => state.hasPsyMedicationsChange == 'Sim'}
            ]}
        />,

        () => <CIDetailPage
            state = {state}
            title = "Ficha do paciente"
            image = {MED}
            changePage = {changePage}
            changeText = {changeText}       
            inputs = {[
                {label: "Você faz tratamento para alguma doença física?", name: "hasPhysicalTreatment", options: ['Sim', 'Não']},
                {label: "Você utiliza medicações para alguma doença física?", name: "hasPhysicalMedications", options: ['Sim', 'Não']},
                {label: "Quais (coloque também as dosagens)?",textarea: true, name: "physical Medications", condition: () => state.hasPhysicalMedications == 'Sim'},
            ]}
        />,

        () => <CIDetailPage
            state = {state}
            title = "Ficha do paciente"
            image = {MED}
            changePage = {changePage}
            changeText = {changeText}       
            inputs = {[
                {label: "Você fuma?", name: "smoke", options: ['Sim', 'Não']},
                {label: "Você bebe?", name: "alcohol", options: ['Sim', 'Não']},
                {label: "Você usa drogas?", name: "drugs", options: ['Sim', 'Não']},
                {label: "Quais?", name: "whichDrugs", condition: () => state.drugs == 'Sim'},
                {label: "Qual frequência?", name: "drugFrequency", condition: () => state.drugs == 'Sim'},
            ]}
        />,

         () => <CIDetailPage

             state = {state}
             title = "Ficha do paciente"
             image = {MED}
             changePage = {changePage}
             changeText = {changeText}
             last   
             inputs = {[
                 {label: "Você faz acompanhamento psicoterápico?", name: "therapy", options: ['Sim', 'Não']},
                 {label: "Qual nome do(a) terapeuta?", name: "therapyName", condition: () => state.therapy == 'Sim'},
                 {label: "Qual telefone dele(a)?", mask: "(99) 99999-9999", name: "therapyPhone", condition: () => state.therapy == 'Sim'},
             ]}
         />,

         () => <ThanksPage/>
    ]
    let pages = commonPages.concat(psyPages)
    return {page: pages[index], length: pages.length}
}

function getNutriAndPersonalPage(state, changeText, changePage, index) {
    let pages = [
        () => <CIDetailPage
            state = {state}
            title = "Ficha do paciente"
            image = {MED}
            changePage = {changePage}
            changeText = {changeText}   
            inputs = {[
                {label: "Por que você está procurando atendimento medico", name: "reason", textarea: true},
                {label: "Você já consulta com psiquiatra?", name: "psychiatrist", options: ['Sim', 'Não']},
                {label: "Quando foi a ultima consulta?", mask: "99/99/9999", condition: () => state.psychiatrist == 'Sim'}
        ]}/>,

        () => <CIDetailPage
            state = {state}
            title = "Ficha do paciente"
            image = {MED}
            changePage = {changePage}
            changeText = {changeText}
            first
            inputs = {[
                {label: "Por que você está procurando atendimento médico", name: "reason", textarea: true},
                {label: "Você já consulta com psiquiatra?", name: "psychiatrist", options: ['Sim', 'Não']},
                {label: "Quando foi a ultima consulta?", mask: "99/99/9999", condition: () => state.psychiatrist == 'Sim'}
        ]}/>,

        
    ]
    return {page: pages[index], length: pages.length}
}

export {getGenericRecordPage, getPsyRecordPage}