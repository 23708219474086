import React, {Component} from 'react';

import '../App.css'

import Doctor from "../../assets/doctor.png"
import LogoNav from "../../assets/logo-nav.png"

import request from '../../util/middleware';

export default class Login extends Component{
    
    constructor(){
        super();
        this.state=({ 
            db:[],
            frase:[], 
            logged:false

        });
    }


    RecuperarFrase=()=> {
        const requestInfo = {
            headers: new Headers({
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              Authorization : `Bearer ${this.state.db} `
            }),
        }; 



        fetch("http://127.0.0.1:8000/api/resp", requestInfo).then((resp)=>resp.json())
        .then((respJson)=> { 
            console.log(JSON.stringify(respJson));  

            this.setState({ 
                
                frase:respJson[0].test
            });
            
            console.log(this.state.frase);   
            
        })
    }

    login = async ()=> {

        let resp = await request('POST', 'login', '', {
            email: this.state.email,
            password: this.state.password,
            strategy: "local"
        })


        if (! resp.ok ) {
            this.setState({error: "Credenciais incorretas"})
            return
        } else {
            localStorage.setItem('accessToken', resp.data.accessToken)
            window.location.replace("http://localhost:3000/medspace")
        }
    }

    setEmail=(value)=>{

        this.setState({
            email:value
        })
    }

    setPassword=(value)=>{

        this.setState({
            password:value
        })
    }

    render(){


        return(
            <div className="bg" >
               <div className="nav-menu d-flex justify-content-center">
                <div className= "logo"> <img  href="https://eurekka.me/terapia" className= "img-nav" src={LogoNav}/> </div>  
                </div>
                
                <div className="container">
                        <div className="blackCard container">  
                        <img className= "imagem " src={Doctor}/>
                        {<div className="alinhamento"><input className = "input in" placeholder = "ID Acesso" value = {this.state.email} onChange = {(evt) => this.setEmail(evt.target.value)}/><br></br>
                        <input type="password" className = "input in" placeholder = "Senha" value = {this.state.password} onChange = {(evt) => this.setPassword(evt.target.value)}/><br></br>
                        
                        <div style = {{color: "red"}}>{this.state.error}</div>
                        <button className = "btnE" onClick = {this.login}>Login </button></div> }
                        
                        </div>
               </div>
            </div>
        );
    }
}