const url = process.env.REACT_APP_API_URL

const requests = {
    "acuity": url + "acuity/?",
    "allPatient": url + "patient?",
    "patient": url + "patient/",
    "create":  url + "api/patient",
    "initial": url + "api/patient/initial-ci",
    "detail": url + "api/patient/detail-ci",
    "getPatient": url + "api/patient/",
    "retryAppoint": url + "acuity/retryAppoint",
    "login": url + "authentication"
}

const request = async (method: "GET"|"POST"|"PUT",url: "login"|"acuity"|"allPatient"|"patient"|"create"|"initial"|"getPatient"|"detail"|"retryAppoint", queryParams: any,body: object, id: String) => {
    try {
        let reqUrl = requests[url] + (id ? id : new URLSearchParams(queryParams))
        if (!reqUrl) return
        
        let authorization = localStorage.getItem('accessToken')
        
        let _headers:any = authorization ? 
            {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': authorization
            } :
            {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }


        let resp = await fetch(reqUrl, {
            method,
            body: body ? JSON.stringify(body) : null,
            headers: _headers
        })
        
        if (resp.status >= 200 && resp.status < 300)
            return  {"ok": true, "data": await resp.json()}
        else
            return {"ok": false, "data": await resp.text()}
    } catch (ex:any) {
        console.log(ex)
        return {"ok": false, "data": ex.toString()}
    }
}

export default request