import React, { Component } from 'react';


import Progress from './progress_indicator'
import LogoNav from '../assets/logo-nav.png'
import request from '../util/middleware';
import AppointmentGoneWrong from './appointment_gone_wrong';
import { getGenericRecordPage, getPsyRecordPage } from './medical_records/general';

export default class CIDetailWizard extends Component {
    
    constructor(props) {
        super(props)

        this.recordsFunctions = {
            "psiquiatria": getPsyRecordPage,
        }

        this.state = {
            currentPage: 0,
            error: false,
            getPageFunction: getGenericRecordPage,
            reason: '',
        }
    }

    componentDidMount() {
        this.fetchUser()
        
    }

    fetchUser = async () => {

        let token = this.props.match.params.token
        let resp = await request('GET', 'patient', null, null, token)
        
        if (resp.ok) {
            
            let patient = resp.data
            
            // Verificando se o checkout deu certo mas o agendamento no Acuity não
            if ( (patient['appointmentStatus']) === false && patient['checkoutStatus'] === true) {
                this.setState({
                    error: true,   
                })
                return
            }

            let specialty = patient['specialty'] ? patient['specialty'].toLowerCase() : patient['specialty']
            this.setState({
                token: patient['_id'],
                
                specialty: patient['specialty'],
                getPageFunction: this.recordsFunctions[specialty] ? this.recordsFunctions[specialty] : getGenericRecordPage,
                
                fullName: patient['fullName'],
                ciEmail: patient['ciEmail'],
                phone: patient['phone'],
                cpf: patient['cpf'],
                street: patient['street'],
                city: patient['city'],
                state: patient['state'],
                zip: patient['zip'],
            })
        }
    }

    changePage = (previous) => {
        
        let nextPage = this.state.currentPage + (previous ?  -1 : 1)
        
        // Checando se é a penultima (antes da página de obrigado)
        if (nextPage == this.state.getPageFunction().length - 1) {
            this.saveData()
        }

        this.setState({
            currentPage: nextPage
        })    
    }


    saveData = async () => {
        let body = this.state
        delete body.currentPage

        let token = this.props.match.params.token
        let resp = await request('PUT', 'patient', null, body, token)
    }

    changeText = (value, index)=>{
        this.setState({
            [index]:value
        })
    }

    
    
    render() {
        let {page, length} = this.state.getPageFunction(this.state, this.changeText, this.changePage, this.state.currentPage)
        return (
            <div className="bg2" >

                <div className="nav-menu d-flex justify-content-center">
                <div className= "logo"> <img  href="https://eurekka.me/terapia" className= "img-nav" src={LogoNav}/> </div>   

                </div>
                <div className="container">

                    {this.state.error ?

                    <AppointmentGoneWrong token = {this.props.match.params.token}/> : 
                    
                    <>
                    {this.state.currentPage === length - 1 ? <></> : <Progress index = {this.state.currentPage} length = {length}/>  }
                    
                    {(page())}
                    </>
                    }

                </div>
            </div>
        );
    }
}
