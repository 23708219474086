import React, { Component } from 'react';
import html2canvas from 'html2canvas'
import {jsPDF} from 'jspdf'
import request from '../../util/middleware';
import moment from 'moment'
import Logo from '../../assets/logo-nav.png'
import Patient from '../../assets/patient.png'

import './dashboard.css'

export default class Dashboard extends Component {
    

    constructor(props) {
        super(props)
        this.state = {
            patients: [],
            totalPatientsInDataBase: 0, 
            isAuth: false,
            skip: 0,
        }
    }

    PATIENT_COUNT_PER_PAGE = 20

    fetchPatients = async () => {

        let params = {
            "\$limit": this.PATIENT_COUNT_PER_PAGE,
            "\$skip": this.PATIENT_COUNT_PER_PAGE * this.state.skip,
            "\$sort[createdAt]": "-1"
        }
        let resp = await request('GET', 'allPatient', params)
        if (resp.ok)
            this.setState({
                patients: resp.data.data,
                totalPatientsInDataBase: resp.data.total,
                isAuth: true
            })
        else 
            this.setState({
                isAuth: false
            })
    }

    componentDidMount() {
        this.fetchPatients()
    }


    downloadPdf = (id, fileName) => {
        const input = document.getElementById(id);
        // input.style.display = "block"
        html2canvas(input)
        .then((canvas) => { 
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm');
            console.log(imgData)
            pdf.addImage(imgData, 'PNG', 0, 0);
            pdf.save(fileName + ".pdf");

        })
    }

    info = (value, big) => {
        let className = big ? "dashboard-info big" : "dashboard-info"
        return(
            <div className = {className}>
                <span>
                    {value}
                </span>
            </div>
        )
    }

    formatPhone = (phone) => {
        return phone.replace("(", "").replace(")", "").replace("-", "").replace(" ", "")
    }


    viewText = (id) => {
        const input = document.getElementById(id);
        input.style.display = input.style.display == "block" ? "none" : "block"
    }

    formatStatus = (status) => {
        if (status === true ) return "Agendado"
        if (status === false) return "Não agendado"
        if (status == "order.paid") return "pago"
        if (status == "inicial") return "Não pagou"
        return status
    }

    getPatientRow = (patient, index) => {
        let divId = "print" + index.toString()
        // Filtrando aqueles que não chegaram a fazer nada
        if (!patient.email) return <></>
        return(
            <>

            <div className="modal fade" tabindex="-1" role="dialog" id = {divId}>
                <div className="modal-dialog" role="document">
                    <div className="modal-content" >
                        <div className="modal-header">
                            <h5 className="modal-title">Informações de {patient.fullName}</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body" >
                            <div id = {'content' + divId}>
                            {Object.entries(patient).map(
                                ([key, value]) => <div>{key + ' --> ' + value} </div>
                            )}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick = {() => this.downloadPdf("content" + divId, patient.fullName)}
                            >
                                Baixar PDF
                            </button>
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <tr>
                <th scope = "row">{patient.fullName}</th>
                <td>{patient.email}</td>
                <td>{this.formatStatus(patient.appointmentStatus)}</td>
                <td>{this.formatStatus(patient.paymentStatus)}</td>
                <td>{moment(patient.date).format('DD/MM à[s] HH:mm')}</td>
                <td>{this.info(moment(patient.createdAt).format('DD/MM à[s] HH:mm'))}</td>
                <td>
                    <a style = {{color: "black"}} href= {"https://wa.me/" + this.formatPhone(patient.phone)}>
                         📞
                     </a>
                    <button className =  "dashboard-button" data-toggle = "modal" data-target = {"#" + divId}>
                        👁
                    </button>
                </td>
            </tr>
            </>
        )
        
    }

    previousPage = () => {

        let state = this.state
        state.skip-- 
        this.setState(state)

        this.fetchPatients()
    }

    nextPage = () => {
        let state = this.state
        state.skip++ 
        this.setState(state)

        this.fetchPatients()
    }

    getIndexesWithCurrentHighlighted = () => {
        let indexes = []
        let indexCounter = 1
        let maximumNumberOfPages = (this.state.totalPatientsInDataBase / this.PATIENT_COUNT_PER_PAGE)
        
        for(let i = this.state.skip; i >= this.state.skip - 4 && i >= 0; i --) {
            
            let isCurrentIndex = i == this.state.skip

            indexes.push(
                
                <li class="page-item">
                    <div class="page-link" style = {{fontWeight: isCurrentIndex && 'bold'}}>{i + 1}</div>
                </li>
            )
        }
        indexes = indexes.reverse()
        for(let i = this.state.skip + 1; i <= this.state.skip + 3 && i < maximumNumberOfPages; i ++) {
            indexes.push(
                <li class="page-item">
                    <div class="page-link">{i + 1}</div>
                </li>
            )
        }

    
        return indexes
    }
    
    render() {
        if (this.state.isAuth == false) return <div>Não autorizado</div>

        let isFirstPage = this.state.skip == 0
        
        let isLastPage = this.state.totalPatientsInDataBase - (this.PATIENT_COUNT_PER_PAGE * this.state.skip) <= this.PATIENT_COUNT_PER_PAGE
        return (
            <>
            <div className="dashboard_body row"> 
                <div className="col-1 dashboard_options">
                  <div className="col-12 dashboard_options_front">
                    <img src={Logo} className="dashboard_logo_image"></img>
                    <img src={Patient} className="dashboard_patient_image"></img> 
                  
                  </div>
    
                </div>
    
                <div className="col-11 dashboard_informations ">
                
                      <div className="col-10 dashboard_navbar">
                          <h2>Olá, seja <span>bem-vindo!</span></h2>
                      </div>
    
                      <div className="dashboard_subject">
                        <h4>Pacientes</h4>
                      </div>
                    
                      <nav aria-label="Page navigation example">
                        <ul class="pagination">
                            <li class="page-item">
                                <button
                                    onClick = {this.previousPage}
                                    className = "btn btn-success"
                                    disabled = {isFirstPage}
                                >
                                    Anterior
                                </button>
                            </li>

                            {this.getIndexesWithCurrentHighlighted()}
                            
                            <button
                                onClick = {this.nextPage}
                                className = "btn btn-success"
                                disabled = {isLastPage}
                            >
                                Próxima
                            </button>
                        </ul>
                      </nav>
                      <div className="dashboard_table">
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col">Nome ⬇⬆</th>
                            <th scope="col">E-mail ⬇⬆</th>
                            <th scope="col">Agendamento ⬇⬆</th>
                            <th scope="col">Pagamento ⬇⬆</th>
                            <th scope="col">Consulta ⬇⬆</th>
                            <th scope="col">Criado em ⬇⬆</th>
                            <th scope="col">Ações ⬇⬆</th>
                          </tr>
                        </thead>
                        <tbody>
                        
                          {this.state.patients.map((patient, index) => 
                            this.getPatientRow(patient, index)
                          )}
                        </tbody>
                      </table>
    
                      </div>
                </div>
            </div>

            </>
        );
    }
}
