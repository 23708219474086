import React, { Component } from 'react';
import Th from '../assets/thanks3.png'
import I from '../assets/instagram.png'
import YT from '../assets/yt.png'

export default class ThanksPage extends Component {
  render() {
    return (
      <>
       <div className="col-12 justify-content-center d-flex thanksPage row ">
         <div className="col-12 d-flex justify-content-center ">
           <img className= "imgthankslg" src={Th}/>
         </div>
         <div className=" col-12 d-flex justify-content-center row ">
            <h4 className="col-12"> Muito obrigado por escolher a Eurekka Med!</h4>
            <h2 className="col-12 d-flex justify-content-center ">Boa consulta</h2> 
         </div>
                        
       </div>
       <h5>Visite-nos</h5>
       
       <div className="col-12 col-lg-7 d-flex justify-content-center"> 
       <a href="https://www.instagram.com/eurekka/"><img  className= "imgthanks" src={I}/></a>
       <a href="https://www.youtube.com/channel/UCATcfSd5QeNgTO8SiwvbKUg"><img  className= "imgthanks" src={YT}/></a>
         </div>
      </>
    );
  }
}
