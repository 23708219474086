import React, { Component } from 'react';
import moment from 'moment'
import './styleSpecialty.css'
import Bg from "../assets/bg.png"
import Logo from "../assets/telemed.png"
import Logo1 from "../assets/telemed1.png"
import Logo2 from "../assets/telemed2.png"

/**
 * @typedef {object} props
 *  
 * @prop {list} specialtys
 * @prop {object} state
 * @prop {Function} handlePickSpecialty
 * 
 * 
 * @extends {Component<Props>}
 */

export default class SpecialtyPage extends Component {


    render () {
        return (<>
        
        <div className="container">
                       
            <div className="row test d-flex justify-content-center">
                        
                <div className="longCard col-10 p-1 time container ">
               
                

                <div className="col-12 mt-3 ml-5"> <h4>Agende agora sua consulta </h4></div>
                <div className="col-12 mb-3 ml-5"> <h6>Fácil, rápido e seguro </h6></div>
                        {/* Mapeando as horas disponíveis */}
                        { this.props.loading ?

                            // Se está carregando
                            <img className= "imagem App-logo" src={Logo}/> :

                            this.props.erro ? 

                            // Se deu erro no fetch
                            <div>{this.props.erro}</div> :
                            
                            // Mapeando as horas
                            // <div>{this.state.hours.toString()}</div>
                            
                            <select  class = "input col-10 col-lg-8 inputStyle " name = "specialty" onChange = {(evt) => this.props.changeText(evt.target.value, 'specialty')}>
                                <option disabled selected value="Selecione a especialidade desejada" >O que gostaria de agendar?</option>
                                { this.props.specialtys.map( specialty =>
                                    <option value = {specialty}>{specialty}</option>    
                                )}

                                    
                        </select>
                                
                        }
                    
                        {<button
                            className="btn btn-dark mb-2"
                            disabled = {this.props.state.specialty ? false : true}
                            onClick = {() => this.props.handlePickSpecialty()}> Buscar</button>} 
                    </div>
                        
                
                
                
                
                <div className="inforow scrolling-wrapper col-10 d-flex justify-content-center">
                    <div className="col-9 col-lg-4 d-flex justify-content-start infoCard animate__animated animate__zoomIn"> 
                        <img  src={Logo}/> 
                        <div className="row">
                        <h3  className="col-6 mb-3 mt-2 ml-3" >Consulta online</h3>
                        <h6 className="col-10 col-lg-8 ml-3">Faça a sua consulta via chamada de vídeo, do conforto da sua casa, com praticidade e segurança.</h6>
                        </div>
                        
                        </div>


                        <div className="col-9 col-lg-4 d-flex justify-content-start infoCard animate__animated animate__zoomIn "> 
                        <img  src={Logo1}/> 
                        <div className="row">
                        <h3  className="col-10 mb-3 mt-2 ml-3" >Profissionais humanizados</h3>
                        <h6 className="col-10 col-lg-8 ml-3">Profissionais escolhidos a dedo pela Eurekka, com alta qualidade e um tratamento humanizado e gentil.</h6>
                        </div>
                        </div>


                        <div className="col-9 col-lg-4 d-flex justify-content-start infoCard animate__animated animate__zoomIn"> 
                        <img  src={Logo2}/> 
                        <div className="row">
                        <h3  className="col-6 mb-3 mt-2 ml-3" >Receita digital</h3>
                        <h6 className="col-10 col-lg-8 ml-3">Através da certificação digital, é possível receitar medicamentos de uso comum, em receitas aceitas nas farmácias de todo o território brasileiro.</h6>
                        </div>
                        </div>
                </div>


            </div></div>
        </>
        )
    }
}